import React from 'react';
import './HomePage.css';

function HomePage() {
  return (
    <div>
      <waves>
        <img id='wave_1' className='wave_group_1' src='./Images/wave_1.svg' alt='' />
        {/* <img id='wave_2' className='wave_group_1' src='./wave_2.svg' alt='Unavailable' /> */}
        <img id='wave_8' className='wave_group_1' src='./Images/wave_8.svg' alt='' /> 
        <img id='wave_7' className='wave_group_1' src='./Images/wave_7.svg' alt='' /> 
        {/* <img id='wave_4' className='wave_group_2' src='./wave_4.svg' alt='Unavailable' /> */}
        {/* <img id='wave_5' className='wave_group_2' src='./wave_5.svg' alt='Unavailable' />
        <img id='wave_6' className='wave_group_2' src='./wave_6.svg' alt='Unavailable' />  */}
      </waves>

      <background>
        <img id='FCC_logo' src='./Images/FCC_logo.png' alt='FCC Sixth Form'/>
        <h1>Felpham Community College <br></br> Enrichment</h1>
        <button id='continue_butt'>Continue</button>
      </background>
    </div>
  )
}

export default HomePage;